var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-404" },
    [
      _c("img", {
        attrs: { src: require("@/assets/images/404.png"), alt: "" },
      }),
      _c("p", [_vm._v("Page not found!")]),
      _c(
        "el-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function ($event) {
              return _vm.$router.back()
            },
          },
        },
        [
          _c("em", { staticClass: "el-icon-back" }),
          _vm._v(" " + _vm._s(_vm.$t("back")) + " "),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }